<template>
	<div class="contracts">
		<a-table
			:columns="columns"
			:data-source="vehicles"
			size="middle"
			:row-key="record => record.rendszam"
			:pagination="{ size: 'normal' }"
		>
			<div
				slot="filterDropdown"
				slot-scope="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }"
				style="padding: 8px"
			>
				<a-input
					v-ant-ref="c => (searchInput = c)"
					:placeholder="`Keresés ${column.dataIndex}`"
					:value="selectedKeys[0]"
					style="width: 188px; margin-bottom: 8px; display: block;"
					@change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
					@pressEnter="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
				/>
				<a-button
					type="primary"
					icon="search"
					size="small"
					style="width: 90px; margin-right: 8px"
					@click="() => handleSearch(selectedKeys, confirm, column.dataIndex)"
				>
					Keresés
				</a-button>
				<a-button size="small" style="width: 90px" @click="() => handleReset(clearFilters)">
					Visszaállítás
				</a-button>
			</div>
			<a-icon
				slot="filterIcon"
				slot-scope="filtered"
				type="search"
				:style="{ color: filtered ? '#108ee9' : undefined }"
			/>
			<template slot="customRender" slot-scope="text, record, index, column">
				<span v-if="searchText && searchedColumn === column.dataIndex">
					<template
						v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
					>
						<mark v-if="fragment.toLowerCase() === searchText.toLowerCase()" :key="i" class="highlight">{{
							fragment
						}}</mark>
						<template v-else>{{ fragment }}</template>
					</template>
				</span>
				<template v-else>
					{{ text }}
				</template>
			</template>

			<template slot="szerzodesesfutasteljesitmeny" slot-scope="szerzodesesfutasteljesitmeny, record">
				{{ record.szerzodesesfutasteljesitmeny | filterNumbers }}
			</template>
			<template slot="elszamolasmodjaName" slot-scope="elszamolasmodja, record">
				{{ record.elszamolasmodja | nameCorrection }}
			</template>
			<template slot="induloertekName" slot-scope="induloertek, record">
				<p v-if="induloertek !== 0">{{ record.induloertek | filterNumbers }}</p>
				<p v-else>-</p>
			</template>

			<template slot="dijosszesenName" slot-scope="dijosszesen, record">
				{{ record.dijosszesen | filterNumbers }}
			</template>
			<template slot="contract" slot-scope="szerzodesid, record">
				<a-button
					v-if="record.elszamolasmodja == 'NYILT_VEGU'"
					icon="file-search"
					shape="circle"
					class="btn-info"
					@click="
						showDrawer(
							record.szerzodesid,
							record.ertekesitonev,
							record.ertekesitotelefon,
							record.futamidohossza,
							record.szerzodesesfutasteljesitmeny
						)
					"
				/>
			</template>
		</a-table>
		<div style="text-align:center; padding-bottom:20px;">
			<download-excel
				class="ant-btn ant-btn-primary"
				propName="CONTRACT"
				:data="vehicles"
				type="csv"
				name="Szerződések.csv"
				style="padding-top:5px;"
				>Mentés Excelbe</download-excel
			>
		</div>
		<a-drawer
			v-if="$mq === 'md' || $mq === 'lg'"
			title="Szolgáltatások"
			placement="right"
			:closable="false"
			:visible="visible"
			@close="onClose"
			:row-key="record => record.rendszam"
			:width="drawerWidth"
			class="services"
		>
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" tab="Szolgáltatások">
					<a-card title="Értékesítő">
						<a-row>
							<a-col :span="12"> {{ name }} </a-col>
							<a-col :span="12"> <a-icon type="phone" theme="filled" /> {{ phone }} </a-col>
						</a-row>
					</a-card>

					<h1 class="servicesTitle">Aktív szolgáltatások</h1>
					<hr />
					<div v-for="service in serviceList()" :key="service.id" class="services">
						<div v-if="service.statusz === 'AKTIV'">
							<a-card>
								<a-row type="flex" justify="center" align="middle">
									<a-col :span="3">
										<img v-if="service.tipusnev === 'Tartósbérlet'" src="@/assets/icons/Wigo_icons_redesign_key.svg" />
										<img
											v-if="service.tipusnev === 'Cégautó adó' || service.tipusnev === 'Gépjárműadó'"
											src="@/assets/icons/Wigo_icons_redesign_car_front.svg"
										/>
										<img
											v-if="service.tipusnev === 'KGFB' || service.tipusnev === 'CASCO'"
											src="@/assets/icons/Wigo_icons_redesign_shield.svg"
										/>
										<img
											v-if="
												service.tipusnev === 'GAP'
											"
											src="@/assets/icons/Wigo_icons_redesign_shield_check.svg"
										/>
										<img
											v-if="
													service.tipusnev === 'Kiterjesztett CASCO'
											"
											src="@/assets/icons/Wigo_icons_redesign_shield_check_plus.svg"
										/>
										<img v-if="service.tipusnev === 'Autópálya használat'" src="@/assets/icons/Wigo_icons_redesign_car_front.svg" />
										<img
											v-if="
												service.tipusnev === 'Belföldi segélyszolgálat' ||
													service.tipusnev === 'Külföldi segélyszolgálat'
											"
											src="@/assets/icons/Wigo_icons_redesign_customer_support.svg"
										/>
										<img
											v-if="
												service.tipusnev === 'Cseregépjármű szolgáltatás'
											"
											src="@/assets/icons/Wigo_icons_redesign_car_side.svg"
										/>
										<img
											v-if="
													service.tipusnev === 'Hozom-viszem szolgáltatás'
											"
											src="@/assets/icons/Wigo_icons_redesign_distance.svg"
										/>
										<img v-if="service.tipusnev === 'Flotta management'" src="@/assets/icons/Wigo_icons_redesign_payment.svg" />
										<img v-if="service.tipusnev === 'GPS'" src="@/assets/icons/Wigo_icons_redesign_poi_map.svg" />
										<img v-if="service.tipusnev === 'Gumiabroncs szolgáltatás'" src="@/assets/icons/Wigo_icons_redesign_wheel.svg" />
										<img v-if="service.tipusnev === 'Parkolás szolgáltatás'" src="@/assets/icons/Wigo_icons_redesign_parkin_spot.svg" />
										<img v-if="service.tipusnev === 'Szerviz szolgáltatás'" src="@/assets/icons/Wigo_icons_redesign_engine.svg" />
										<img v-if="service.tipusnev === 'Üzemanyagkártya'" src="@/assets/icons/Wigo_icons_redesign_fuel_card.svg" />
									</a-col>
									<a-col :span="10">
										<p v-if="service.tipusnev === 'Flotta management'">Flotta díj</p>
										<p v-else-if="service.tipusnev === 'Hozom-viszem szolgáltatás'">
											Hozom-viszem szolg.
										</p>
										<p v-else-if="service.tipusnev === 'GAP'">
											GAP biztosítás
										</p>
										<p v-else-if="service.tipusnev === 'GPS'">
											GPS szolgáltatás
										</p>

										<p v-else>{{ service.tipusnev }}</p>
									</a-col>
									<a-col :span="1">
										<a-divider type="vertical" />
									</a-col>
									<a-col :span="6">
										<p>{{ service.dij | filterNumbers }} Ft</p>
									</a-col>
									<a-col :span="1">
										<a-divider type="vertical" />
									</a-col>
									<a-col :span="2"><img src="@/assets/active.png"/></a-col>
								</a-row>
							</a-card>
						</div>
					</div>
					<h1 class="servicesTitle">Megrendelés alatt</h1>
					<hr />
					<div v-for="service in serviceList()" :key="service.id" class="services">
						<div
							v-if="
								service.statusz === 'MEGRENDELES_ALATT' &&
									service.tipusnev != 'Flotta management' &&
									service.tipusnev != 'Cégautó adó' &&
									service.tipusnev != 'Gépjárműadó'
							"
						>
							<a-card>
								<a-row type="flex" justify="center" align="middle">
									<a-col :xl="{ span: 2 }" :lg="{ span: 2 }" :md="{ span: 2 }" :sm="{ span: 2 }" :xs="{ span: 2 }">
										<img v-if="service.tipusnev === 'Tartósbérlet'" src="@/assets/icons/Wigo_icons_redesign_key.svg" />

										<img
											v-if="service.tipusnev === 'KGFB' || service.tipusnev === 'CASCO'"
											src="@/assets/icons/Wigo_icons_redesign_shield.svg"
										/>
										<img
											v-if="
												service.tipusnev === 'GAP'
											"
											src="@/assets/icons/Wigo_icons_redesign_shield_check.svg"
										/>
										<img
											v-if="
													service.tipusnev === 'Kiterjesztett CASCO'
											"
											src="@/assets/icons/Wigo_icons_redesign_shield_check_plus.svg"
										/>
										<img v-if="service.tipusnev === 'Autópálya használat'" src="@/assets/icons/Wigo_icons_redesign_car_front.svg" />
										<img v-if="service.tipusnev === 'GPS'" src="@/assets/icons/Wigo_icons_redesign_poi_map.svg" />
										<img
											v-if="
												service.tipusnev === 'Belföldi segélyszolgálat' ||
													service.tipusnev === 'Külföldi segélyszolgálat'
											"
											src="@/assets/icons/Wigo_icons_redesign_customer_support.svg"
										/>
										<img
											v-if="
												service.tipusnev === 'Cseregépjármű szolgáltatás'
											"
											src="@/assets/icons/Wigo_icons_redesign_car_side.svg"
										/>
										<img
											v-if="
													service.tipusnev === 'Hozom-viszem szolgáltatás'
											"
											src="@/assets/icons/Wigo_icons_redesign_distance.svg"
										/>

										<img v-if="service.tipusnev === 'Gumiabroncs szolgáltatás'" src="@/assets/icons/Wigo_icons_redesign_wheel.svg" />
										<img v-if="service.tipusnev === 'Parkolás szolgáltatás'" src="@/assets/icons/Wigo_icons_redesign_parkin_spot.svg" />
										<img v-if="service.tipusnev === 'Szerviz szolgáltatás'" src="@/assets/icons/Wigo_icons_redesign_engine.svg" />
										<img v-if="service.tipusnev === 'Üzemanyagkártya'" src="@/assets/icons/Wigo_icons_redesign_fuel_card.svg" />

										<img v-if="service.tipusnev === 'Flotta management'" src="@/assets/icons/Wigo_icons_redesign_payment.svg" />
										<img
											v-if="service.tipusnev === 'Cégautó adó' || service.tipusnev === 'Gépjárműadó'"
											src="@/assets/icons/Wigo_icons_redesign_car_front.svg"
										/>
									</a-col>
									<a-col :span="14">
										<p v-if="service.tipusnev === 'Flotta management'">Flotta díj</p>
										<p v-else-if="service.tipusnev === 'Hozom-viszem szolgáltatás'">
											Hozom-viszem szolg.
										</p>
										<p v-else-if="service.tipusnev === 'GAP'">
											GAP biztosítás
										</p>
										<p v-else-if="service.tipusnev === 'GPS'">
											GPS szolgáltatás
										</p>
										<p v-else>{{ service.tipusnev }}</p>
									</a-col>
								</a-row>
							</a-card>
						</div>
					</div>
					<h1 class="servicesTitle">Inaktív szolgáltatások</h1>
					<hr />
					<div v-for="service in serviceList()" :key="service.id" class="services">
						<div
							v-if="
								service.statusz === 'INAKTIV' &&
									service.tipusnev != 'Flotta management' &&
									service.tipusnev != 'Cégautó adó' &&
									service.tipusnev != 'Gépjárműadó'
							"
						>
							<a-card>
								<a-row type="flex" justify="center" align="middle">
									<a-col :xl="{ span: 2 }" :lg="{ span: 2 }" :md="{ span: 2 }" :sm="{ span: 2 }" :xs="{ span: 2 }">
										<img v-if="service.tipusnev === 'Tartósbérlet'" src="@/assets/icons/Wigo_icons_redesign_key.svg" />

										<img
											v-if="service.tipusnev === 'KGFB' || service.tipusnev === 'CASCO'"
											src="@/assets/icons/Wigo_icons_redesign_shield.svg"
										/>
										<img
											v-if="
												service.tipusnev === 'GAP'
											"
											src="@/assets/icons/Wigo_icons_redesign_shield_check.svg"
										/>
										<img
											v-if="
													service.tipusnev === 'Kiterjesztett CASCO'
											"
											src="@/assets/icons/Wigo_icons_redesign_shield_check_plus.svg"
										/>
										<img v-if="service.tipusnev === 'Autópálya használat'" src="@/assets/icons/Wigo_icons_redesign_car_front.svg" />
										<img v-if="service.tipusnev === 'GPS'" src="@/assets/icons/Wigo_icons_redesign_poi_map.svg" />
										<img
											v-if="
												service.tipusnev === 'Belföldi segélyszolgálat' ||
													service.tipusnev === 'Külföldi segélyszolgálat'
											"
											src="@/assets/icons/Wigo_icons_redesign_customer_support.svg"
										/>
										<img
											v-if="
												service.tipusnev === 'Cseregépjármű szolgáltatás'
											"
											src="@/assets/icons/Wigo_icons_redesign_car_side.svg"
										/>
										<img
											v-if="
													service.tipusnev === 'Hozom-viszem szolgáltatás'
											"
											src="@/assets/icons/Wigo_icons_redesign_distance.svg"
										/>

										<img v-if="service.tipusnev === 'Gumiabroncs szolgáltatás'" src="@/assets/icons/Wigo_icons_redesign_wheel.svg" />
										<img v-if="service.tipusnev === 'Parkolás szolgáltatás'" src="@/assets/icons/Wigo_icons_redesign_parkin_spot.svg" />
										<img v-if="service.tipusnev === 'Szerviz szolgáltatás'" src="@/assets/icons/Wigo_icons_redesign_engine.svg" />
										<img v-if="service.tipusnev === 'Üzemanyagkártya'" src="@/assets/icons/Wigo_icons_redesign_fuel_card.svg" />

										<img v-if="service.tipusnev === 'Flotta management'" src="@/assets/icons/Wigo_icons_redesign_payment.svg" />
										<img
											v-if="service.tipusnev === 'Cégautó adó' || service.tipusnev === 'Gépjárműadó'"
											src="@/assets/icons/Wigo_icons_redesign_car_front.svg"
										/>
									</a-col>
									<a-col :span="14">
										<p v-if="service.tipusnev === 'Flotta management'">Flotta díj</p>
										<p v-else-if="service.tipusnev === 'Hozom-viszem szolgáltatás'">
											Hozom-viszem szolg.
										</p>
										<p v-else-if="service.tipusnev === 'GAP'">
											GAP biztosítás
										</p>
										<p v-else-if="service.tipusnev === 'GPS'">
											GPS szolgáltatás
										</p>
										<p v-else>{{ service.tipusnev }}</p>
									</a-col>
									<a-col :span="1">
										<a-divider type="vertical" />
									</a-col>

									<a-col :span="6"
										><a-button shape="round" @click="order(service.tipus)">Ajánlatot kérek</a-button></a-col
									>
								</a-row>
							</a-card>
						</div>
					</div>
				</a-tab-pane>
				<a-tab-pane key="2" tab="Szolgáltatások kilométerre vetített költségei" force-render>
					<div class="total">
						<a-card>
							<a-row>
								<a-col :span="12"> TCO: </a-col>
								<a-col :span="12"> {{ total }} Ft/km </a-col>
							</a-row>
						</a-card>
					</div>
					<a-row type="flex" justify="center">
						<apexchart type="pie" :width="width" :height="height" :options="chartOptions" :series="series"></apexchart>
					</a-row>

					<div style="text-align:center; padding:20px 0 20px 0;">
						<download-excel
							class="ant-btn ant-btn-primary"
							:data="excelPerKm"
							propName="SERVICE"
							type="csv"
							name="Szolgáltatások költségei.csv"
							style="padding-top:5px;"
							>Mentés Excelbe</download-excel
						>
					</div>
				</a-tab-pane>
			</a-tabs>
		</a-drawer>
		<a-drawer
			v-if="$mq === 'sm' || $mq === 'tablet'"
			title="Szolgáltatások"
			placement="right"
			:closable="false"
			:visible="visible"
			@close="onClose"
			:row-key="record => record.rendszam"
			width="80%"
			class="services"
		>
			<div align="center">
				<a-icon @click="closeDrawer" type="rollback" />
			</div>
			<a-tabs default-active-key="1">
				<a-tab-pane key="1" tab="Szolgáltatások">
					<a-card title="Értékesítő">
						<a-row>
							<a-col :span="24"> {{ name }} </a-col>
							<a-col :span="24"> <a-icon type="phone" theme="filled" /> {{ phone }} </a-col>
						</a-row>
					</a-card>

					<h1 class="servicesTitle">Aktív szolgáltatások</h1>
					<hr />
					<div v-for="service in serviceList()" :key="service.id">
						<a-row v-if="service.statusz === 'AKTIV'">
							<a-col :span="24">
								<a-card :title="service.tipusnev">
									<p>{{ service.dij | filterNumbers }} Ft</p>
								</a-card>
							</a-col>
						</a-row>
					</div>
					<h1 class="servicesTitle">Megrendelés alatt</h1>
					<hr />
					<div v-for="service in serviceList()" :key="service.id">
						<a-row v-if="service.statusz === 'MEGRENDELES_ALATT'">
							<a-col :span="24">
								<a-card>
									<p>{{ service.tipusnev }}</p>
								</a-card>
							</a-col>
						</a-row>
					</div>

					<h1 class="servicesTitle">Inaktív szolgáltatások</h1>
					<hr />
					<div v-for="service in serviceList()" :key="service.id">
						<a-row
							v-if="
								service.statusz === 'INAKTIV' &&
									service.tipusnev != 'Flotta management' &&
									service.tipusnev != 'Cégautó adó' &&
									service.tipusnev != 'Gépjárműadó'
							"
						>
							<a-col :span="24">
								<a-card :title="service.tipusnev">
									<a-button shape="round" @click="order(service.tipus)">Ajánlatot kérek</a-button>
								</a-card>
							</a-col>
						</a-row>
					</div>
				</a-tab-pane>
				<a-tab-pane key="2" tab="Szolgáltatások kilométerre vetített költségei" force-render>
					<div class="total">
						<a-card>
							<a-row>
								<a-col :span="12"> TCO: </a-col>
								<a-col :span="12"> {{ total }} Ft/km </a-col>
							</a-row>
						</a-card>
					</div>
					<a-row type="flex" justify="center">
						<apexchart type="pie" :width="300" :height="height" :options="chartOptions" :series="series"></apexchart>
					</a-row>

					<div style="text-align:center; padding:20px 0 20px 0;">
						<download-excel
							class="ant-btn ant-btn-primary"
							:data="excelPerKm"
							propName="SERVICE"
							type="csv"
							name="Szolgáltatások költségei.csv"
							style="padding-top:5px;"
							>Mentés Excelbe</download-excel
						>
					</div>
				</a-tab-pane>
			</a-tabs>
		</a-drawer>
	</div>
</template>

<script>
import { mapState } from 'vuex';
//import Vue from 'vue';
//import excel from 'vue-excel-export';
//Vue.use(excel);

const columns = [
	{
		title: 'Szerződésszám',
		dataIndex: 'szerzodesszam',
		key: 'szerzodesszam',
		scopedSlots: {
			filterDropdown: 'filterDropdown',
			filterIcon: 'filterIcon',
			customRender: 'customRender',
		},
		onFilter: (value, record) =>
			record.szerzodesszam
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => {
					this.searchInput.focus();
				}, 0);
			}
		},
		sorter: (a, b) => a.szerzodesszam.localeCompare(b.szerzodesszam),
	},
	{
		title: 'Rendszám',
		dataIndex: 'rendszam',
		key: 'rendszam',
		scopedSlots: {
			filterDropdown: 'filterDropdown',
			filterIcon: 'filterIcon',
			customRender: 'customRender',
		},
		onFilter: (value, record) =>
			record.rendszam
				.toString()
				.toLowerCase()
				.includes(value.toLowerCase()),
		onFilterDropdownVisibleChange: visible => {
			if (visible) {
				setTimeout(() => {
					this.searchInput.focus();
				}, 0);
			}
		},
		sorter: (a, b) => a.rendszam.localeCompare(b.rendszam),
	},
	{
		title: 'Futamidő kezdete',
		dataIndex: 'futamidokezdete',
		key: 'futamidokezdete',
		sorter: (a, b) => new Date(a.futamidokezdete) - new Date(b.futamidokezdete),
	},
	{
		title: 'Futamidő vége',
		dataIndex: 'futamidovege',
		key: 'futamidovege',
		sorter: (a, b) => new Date(a.futamidovege) - new Date(b.futamidovege),
	},
	{
		title: 'Futamidő',
		dataIndex: 'futamidohossza',
		key: 'futamidohossza',
		sorter: (a, b) => a.futamidohossza - b.futamidohossza,
	},
	{
		title: 'Szerződéses futásteljesítmény (km)',
		dataIndex: 'szerzodesesfutasteljesitmeny',
		key: 'szerzodesesfutasteljesitmeny',
		scopedSlots: { customRender: 'szerzodesesfutasteljesitmeny' },
		sorter: (a, b) => a.teljesitmeny - b.teljesitmeny,
	},
	{
		title: 'Elszámolás módja',
		dataIndex: 'elszamolasmodja',
		key: 'elszamolasmodja',
		scopedSlots: { customRender: 'elszamolasmodjaName' },
	},
	{
		title: 'Induló bérleti díj (nettó)',
		dataIndex: 'induloertek',
		key: 'induloertek',
		scopedSlots: { customRender: 'induloertekName' },
		sorter: (a, b) => a.induloertek - b.induloertek,
	},
	{
		title: 'Díj összesen',
		dataIndex: 'dijosszesen',
		key: 'dijosszesen',
		scopedSlots: { customRender: 'dijosszesenName' },
		sorter: (a, b) => a.dijosszesen - b.dijosszesen,
	},
	{
		title: 'Devizanem',
		dataIndex: 'deviza',
		key: 'deviza',
	},
	{
		title: 'Részletek',
		key: 'details',
		scopedSlots: { customRender: 'contract' },
	},
];

export default {
	filters: {
		nameCorrection: function(value) {
			if (!value) return '';
			if (value === 'NYILT_VEGU') {
				value = 'Elszámolásos';
				return value;
			} else {
				value = 'Zárt';
				return value;
			}
		},
	},
	data() {
		return {
			total: '',
			searchText: '',
			searchInput: null,
			searchedColumn: '',
			excelPerKm: [],
			name: '',
			phone: '',
			contractId: '',
			duration: '',
			mileage: '',
			columns,
			visible: false,
			drawerWidth: 800,
			width: 500,
			height: 500,
			series: [],
			chartOptions: {
				chart: {
					type: 'pie',
				},
				legend: {
					position: 'bottom',
				},

				dataLabels: {
					// formatter: function(value, { seriesIndex, dataPointIndex, w }) {
					// 	return Math.round(value) + '%' + ' - ' + w.config.series[seriesIndex] + ' HUF';
					// 	return Math.round(value) + '%' + ' - ' + w.config.labels[seriesIndex];
					// },
					formatter: function(val, opt) {
						return opt.w.config.series[opt.seriesIndex] + ' Ft/km';
					},
				},

				colors: [
					'#5EB1EB',
					'#FEA83F',
					'#FF7B97',
					'#385486',
					'#FC0F4C',
					'#944EE6',
					'#083414',
					'#2447BD',
					'#20DD87',
					'#BEC372',
					'#5CEEB4',
					'#305E74',
					'#B66187',
					'#C190BF',
					'#2BF574',
					'#8FAB98',
					'#8AA458',
					'#DD458C',
				],
				labels: [
					'Bérleti díj',
					'Cégautó adó',
					'Gépjárműadó',
					'KGFB díj',
					'CASCO díj',
					'GAP',
					'Autópálya használat',
					'Belföldi segélyszolgálat',
					'Cseregépjármű szolgáltatás',
					'Flotta management díj',
					'GPS díj',
					'Gumiabroncs szolgáltatás',
					'Hozom-viszem szolgáltatás',
					'Kiterjesztett CASCO díj',
					'Külföldi segélyszolgálat',
					'Parkolás szolgáltatás',
					'Szerviz szolgáltatás',
					'Üzemanyagkártya díj',
				],
				responsive: [],
			},
		};
	},

	created() {
		this.$store.dispatch('listVehiclesContracts', {
			page: 0,
		});
		this.$store.dispatch('listContractsList', {
			page: 0,
		});
	},

	computed: {
		...mapState({ vehicles: state => state.auth.listContractsList }),
	},

	mounted() {},
	methods: {
		handleSearch(selectedKeys, confirm, dataIndex) {
			confirm();
			this.searchText = selectedKeys[0];
			this.searchedColumn = dataIndex;
		},

		handleReset(clearFilters) {
			clearFilters();
			this.searchText = '';
		},
		showDrawer(id, name, phone, duration, mileage) {
			this.duration = duration;
			this.mileage = mileage;
			this.visible = true;
			this.name = name;
			this.phone = phone;
			this.contractId = id;

			this.$store
				.dispatch('listServices', {
					id: this.contractId,
				})
				.then(() => {
					this.serviceList();
					this.calculation();
					//console.log(this.serviceList());
				})
				.catch(err => {
					this.$showNotification(
						'Hiba',
						err.response.data.detail,
						6,
						<a-icon type="close-circle" style="color:#fc0a33" />
					);
				});
		},

		calculation() {
			const durationPerMonth = this.mileage / this.duration;

			this.serviceList().forEach(service => {
				this.series.push(Math.round((service.dij / durationPerMonth) * 100) / 100);

				let calculatedObject = {
					//dij: (Math.round((service.dij / durationPerMonth) * 100) / 100),

					statusz: service.statusz,
					szolgaltatasid: service.szolgaltatasid,
					tipus: service.tipus,
					tipusnev: service.tipusnev,
					dij: service.dij,
				};
				this.excelPerKm.push(calculatedObject);
			});
			this.total = this.series.reduce((a, b) => a + b).toFixed(1);
		},

		serviceList() {
			return this.$store.state.auth.serviceList;
		},
		onClose() {
			this.visible = false;
			this.series = [];
		},

		closeDrawer() {
			this.visible = false;
			this.series = [];
		},

		order(type) {
			this.$store
				.dispatch('requestServices', {
					vehicleId: this.contractId,
					orderedServiceType: type,
				})
				.then(() => {
					this.$showNotification(
						'Rendben',
						'Sikeres megrendelés!',
						6,
						<a-icon type="check-circle" style="color:#00ce55" />
					);
				})
				.catch(err => {
					this.$showNotification(
						'Hiba',
						err.response.data.detail,
						6,
						<a-icon type="close-circle" style="color:#fc0a33" />
					);
				});
		},
	},
};
</script>
<style lang="scss" scoped>
.btn-info.ant-btn, .ant-btn-primary.ant-btn {
	background: #6d8cb5!important;
	border-color: #6d8cb5 !important;
}
/deep/ .total .ant-card {
	text-align: center;
	margin: auto;
	width: 60%;
}

.anticon-search {
	background: #6d8cb5 !important;
	font-size: 20px !important;
	top: -3px !important;
	right: 1px !important;
}
.servicesTitle {
	color: #1f4e8d;
	font-size: 2em;
	font-weight: 900;
}
hr {
	border: 1px solid #1f4e8d;
}

.services .ant-divider {
	background: #1f4e8d;
	font-size: 1.5em;
}

.services img {
	width: 75%;
}

.services p {
	font-size: 1em;
	margin-top: 0px !important;
	margin-bottom: 0em !important;
}
/deep/ .ant-card {
	text-align: center !important;
	color: #1f4e8d;
	box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.6);
	border: none;
	margin: 15px;
	border-radius: 10px 10px !important;
}
/deep/ .ant-card-body {
	text-align: center !important;
	color: #1f4e8d;
	font-size: 1.5em;
}
/deep/ .ant-card-head {
	padding: 0px 0px !important;
	border-radius: 10px 10px !important;
}
/deep/ .ant-card-head-title {
	text-align: center !important;
	color: white;
	background: #1f4e8d !important;
	font-size: 1.5em;
	border-radius: 10px 10px 0px 0px !important;
}

/deep/ .ant-card-head {
	background: #1f4e8d !important;
}

/deep/ .ant-drawer-header {
	text-align: center !important;
}

/deep/ .ant-tabs-bar {
	text-align: center !important;
	background: white !important;
}

/deep/ .ant-tabs-nav-scroll {
	text-align: center !important;
}

/deep/ .ant-tabs-tab:hover {
	color: #1f4e8d !important;
}

/deep/ .ant-tabs-tab-active {
	color: #1f4e8d !important;
}

/deep/ .ant-tabs-nav {
	color: #1f4e8d !important;
	font-size: 1em;
}

/deep/ .ant-tabs-ink-bar {
	background: #1f4e8d !important;
}
@media (max-width: 576px) {
	/deep/ .total .ant-card-body {
		font-size: 1em !important;
	}
	/deep/ .total .ant-card {
		width: 80%;
	}
	/* Target devices narrower than 578px. */
	/deep/ .ant-tabs-tab-active,
	/deep/ .ant-tabs-tab {
		font-size: 0.7em !important;
	}

	/deep/ .ant-tabs-bar {
		padding: 0 0 !important;
	}

	/deep/ .ant-card-head-title {
		font-size: 1em;
	}

	.servicesTitle {
		font-size: 1em;
	}
}
</style>

<style lang="scss">
@import '@/styles/_global.scss';
</style>
